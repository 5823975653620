import Content from "components/Content";
import Label from "components/Label";
import React, { FC } from "react";
import styles from "./styles.module.scss";
import Logo from "../../../../../public/full-logo-primary.svg";
import Logomark from "../../../../../public/logomark.svg";

const rows = [
  {
    columns: ["24/7 Home Emergency Support", "No resource", "On demand"]
  },
  {
    columns: ["Tele-maintenance", "No resource", "On demand"]
  },
  {
    columns: [
      "High Quality, Vetted Service Pros",
      "Hours to find",
      "Same & next day availability"
    ]
  },
  {
    columns: [
      "Personalized Maintenance & Improvement",
      "Generic checklists",
      "Personalized for your home"
    ]
  },
  {
    columns: ["Home Inspection", "$800", "Discounted For Members"]
  }
];

const TheDifference: FC = () => {
  return (
    <Content
      containerClassName={styles.wrapper}
      contentClassName={styles.container}
    >
      <Label className={styles.title} color="primary" variant="h3-centered">
        The Humming Homes Difference
      </Label>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <td>
                <div className={styles.label}>
                  <Label variant="h5">Service</Label>
                </div>
              </td>
              <td>
                <div className={styles.label}>
                  <Label variant="h5-centered">On Your Own</Label>
                </div>
              </td>
              <td>
                <div className={styles.label}>
                  <div className={styles.logo}>
                    <Logo />
                  </div>
                  <div className={styles.logomark}>
                    <Logomark />
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.columns[0]}>
                {row.columns.map((column) => (
                  <td key={column}>
                    <div className={styles.label}>{column}</div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Content>
  );
};

export default TheDifference;
