import Label from "components/Label";
import React, { FC } from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import Content from "components/Content";

import { BsCheckCircleFill as CheckIcon } from "react-icons/bs";

import text from "constants/text";
import useScreenSize from "hooks/use-screen-size";

import Button from "components/Button";
import styles from "./styles.module.scss";

const HowItWorks: FC<{
  onClickGetStarted?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}> = ({ onClickGetStarted }) => {
  const { isResponsive, isMobile, screenWidth } = useScreenSize();

  const router = useRouter();

  const {
    title,
    membership,
    app_highlights: highlights
  } = text.homepage.your_membership;

  const { price, price_monthly: priceMonthly, includes, cta } = membership;

  return (
    <Content
      contentClassName={styles.container}
      containerClassName={styles.wrapper}
    >
      <Label
        color="white"
        variant="s-capitalized-centered"
        className={styles.title}
      >
        {title}
      </Label>
      <div className={styles.content}>
        <div className={styles.leftPanel}>
          <Image
            alt=""
            src={highlights}
            width={
              isResponsive ? (isMobile ? Math.min(screenWidth, 330) : 360) : 608
            }
            height={
              isResponsive
                ? isMobile
                  ? Math.min(screenWidth, 330) / 1.04
                  : 290
                : 480
            }
          />
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.membership}>
            <div className={styles.membershipHeader}>
              <Label
                className={styles.priceText}
                variant="s-capitalized"
                color="supplemental"
              >
                Starting at
              </Label>
              <div className={styles.price}>
                <Label
                  className={styles.priceLabel}
                  variant="h2"
                  color="primary"
                >{`$${price}`}</Label>
                <Label
                  className={styles.monthLabel}
                  variant="h"
                  color="primary"
                >
                  / year
                </Label>
              </div>
              <Label
                className={styles.priceText}
                variant="s-capitalized"
                color="supplemental"
              >
                or
              </Label>
              <div className={styles.price}>
                <Label
                  className={styles.priceLabel}
                  variant="h2"
                  color="primary"
                >{`$${priceMonthly}`}</Label>
                <Label
                  className={styles.monthLabel}
                  variant="h"
                  color="primary"
                >
                  / month
                </Label>
              </div>
            </div>
            <div className={styles.items}>
              {includes.map((item) => (
                <div className={styles.item}>
                  <CheckIcon className={styles.check} />
                  <Label
                    className={styles.itemLabel}
                    variant="s"
                    color="primary"
                  >
                    {item}
                  </Label>
                </div>
              ))}
            </div>
            <Button className={styles.cta} variant="cta">
              {cta.reads}
            </Button>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default HowItWorks;
