import { useState, useCallback, useLayoutEffect } from "react";
import { debounce } from "lodash";

const getScreenSize = () => ({
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight
});

const useScreenSize = (): {
  screenWidth: number;
  screenHeight: number;
  isResponsive: boolean;
  isMobile: boolean;
  isTablet: boolean;
} => {
  const [parameters, setParameters] = useState<{
    screenWidth: number;
    screenHeight: number;
  }>({ screenHeight: 0, screenWidth: 0 });

  const handleUpdateScreen = useCallback(
    debounce(() => {
      setParameters(getScreenSize());
    }, 50),
    []
  );

  useLayoutEffect(() => {
    handleUpdateScreen();

    window.addEventListener("resize", handleUpdateScreen);

    return () => window.removeEventListener("resize", handleUpdateScreen);
  }, [handleUpdateScreen]);

  const isResponsive = parameters.screenWidth < 1200;
  const isMobile = parameters.screenWidth < 744;

  return {
    ...parameters,
    isResponsive,
    isMobile,
    isTablet: isResponsive && !isMobile
  };
};

export default useScreenSize;
