export const ROTATIONS = {
  up: {
    initial: "rotate(180deg)",
    final: "rotate(0deg)"
  },
  down: {
    initial: "rotate(0deg)",
    final: "rotate(180deg)"
  }
};
