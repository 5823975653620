/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  FC,
  MutableRefObject,
  useEffect,
  useCallback,
  useMemo,
  ReactNode
} from "react";
import { motion, useDragControls } from "framer-motion";
import { HiChevronDown as CollapsedIcon } from "react-icons/hi";
import { v4 as uuid } from "uuid";

import styles from "./styles.module.scss";
import { ROTATIONS } from "./constants";

type Props = {
  header: React.ReactElement;
  className?: string;
  headerClassName?: string;
  disabled?: boolean;
  withIcon?: boolean;
  collapseDirection?: "up" | "down";
  drag?: boolean | "x";
  dragConstraints?: MutableRefObject<HTMLElement | null>;
  unmountOnCollapse?: boolean;
  withBackdrop?: boolean;
  height?: number | string;
  open?: boolean;
  onSelect?(id: string): void;
  id?: string;
  children: ReactNode;
  iconClassName?: string;
  collapsedIcon?: ReactNode;
  openIcon?: ReactNode;
};

const Collapsable: FC<Props> = ({
  header,
  children,
  className,
  headerClassName,
  disabled,
  withIcon,
  collapseDirection = "down",
  drag = false,
  dragConstraints,
  unmountOnCollapse = false,
  withBackdrop = false,
  height,
  open,
  id,
  onSelect,
  iconClassName,
  collapsedIcon,
  openIcon
}) => {
  const componentId = useMemo(() => id ?? uuid(), []);
  const [collapsed, setCollapsed] = useState(true);
  const dragControls = useDragControls();

  const [unmountChildren, setUnmountChildren] = useState(true);

  useEffect(() => {
    if (!unmountChildren) setCollapsed(false);
  }, [unmountChildren]);

  const documentClickHandler = useCallback((event: any) => {
    if (!event.target.closest(`#collapsable-${componentId}`))
      setCollapsed(true);
  }, []);

  useEffect(() => {
    if (withBackdrop) document.addEventListener("click", documentClickHandler);
    return () => document.removeEventListener("click", documentClickHandler);
  }, []);

  const Icon =
    openIcon && collapsedIcon ? (
      (open === undefined ? collapsed : !open) ? (
        collapsedIcon
      ) : (
        openIcon
      )
    ) : (
      <CollapsedIcon
        className={`${styles.icon} ${iconClassName}`}
        style={{
          transition: "all 0.2s ease-in-out",
          transform: (open === undefined ? collapsed : !open)
            ? ROTATIONS[collapseDirection].initial
            : ROTATIONS[collapseDirection].final
        }}
      />
    );

  return (
    <motion.div
      id={`collapsable-${componentId}`}
      dragControls={dragControls}
      dragListener={false}
      dragConstraints={dragConstraints}
      drag={drag}
      dragMomentum={false}
      className={`${styles.container} ${className}`}
    >
      {drag && (
        <div
          onPointerDown={(event) => dragControls.start(event)}
          className={styles.handle}
        />
      )}
      <div
        className={`${styles.header} ${headerClassName}`}
        aria-hidden
        onClick={
          disabled
            ? () => {}
            : () => {
                if (onSelect) onSelect(componentId);
                setCollapsed(!collapsed);
                if (collapsed) setUnmountChildren(false);
              }
        }
      >
        {header}
        {withIcon && Icon}
      </div>
      <motion.div
        initial={false}
        onAnimationComplete={() => {
          if (collapsed) setUnmountChildren(true);
        }}
        animate={
          (open === undefined ? collapsed : !open)
            ? { height: 0 }
            : { height: height ?? "fit-content", transition: { delay: 0.05 } }
        }
        className={styles.children}
      >
        {(unmountOnCollapse ? !unmountChildren : true) && children}
      </motion.div>
    </motion.div>
  );
};

Collapsable.defaultProps = {
  className: "",
  headerClassName: "",
  disabled: false,
  withIcon: false,
  collapseDirection: "down",
  drag: false,
  dragConstraints: undefined,
  unmountOnCollapse: false,
  withBackdrop: false,
  height: undefined,
  open: undefined,
  onSelect: undefined,
  id: undefined,
  iconClassName: undefined,
  collapsedIcon: null,
  openIcon: null
};

export default Collapsable;
